import React from "react";
import strings from "../strings.js"
import { Button } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const handleClickScheduleAppt = () => {
  window.location.href = "https://www.doctorim.co.il/s/doctor-10016883/%D7%93%D7%A8-%D7%90%D7%A4%D7%A8%D7%AA-%D7%A4%D7%9C%D7%99%D7%99%D7%A1%D7%99%D7%92/%D7%9E%D7%95%D7%9E%D7%97%D7%99%D7%AA-%D7%A2%D7%99%D7%A0%D7%99%D7%99%D7%9D";
};

const handleClickCall = () => {
  window.location.href = `tel:${strings.phone}`;
};

const handleClickWhatsapp = () => {
  window.location.href = 'https://wa.me/972523885054';
};

function ToSchedule() {
  return (

  <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>

      <p className="Secondary-text" style={{color: '#00008b', textAlign: 'center', fontSize: '1.5em', lineHeight: 2}}>
      <Button style={{
        backgroundColor: '#FFD700',
        color: 'black',
        fontSize: '24px',
        border: 'none',
        padding: '2px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
    }} onClick={handleClickScheduleAppt}>לקביעת תור לחצו כאן</Button>
      <br />
      <Button style={{
        backgroundColor: '#4052d6',
        color: 'white',
        fontSize: '24px',
        border: 'none',
        padding: '2px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
    }} onClick={handleClickCall}><FontAwesomeIcon icon={faPhone} style={{
      color: 'white',
      fontSize: '24px',
      marginRight: '5px'
  }}/>   {strings.phone}</Button>
      <br />
      <Button style={{
        backgroundColor: '#6d8196',
        color: 'white',
        fontSize: '24px',
        border: 'none',
        padding: '2px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
    }}  onClick={handleClickWhatsapp}><FontAwesomeIcon icon={faWhatsapp} style={{
        color: '#25D366',
        fontSize: '32px',
        marginRight: '5px'
    }} />   {strings.secondPhone}</Button>
      <br />
      </p>
  </div>
  );
}

export default ToSchedule;