import React from "react";
import strings from "../strings.js"
import Efrat from "../images/Efrat.jpg"
import eye from "../images/eye.png"
import op1 from "../images/op1.jpg"
import op2 from "../images/op2.jpg"
import op3 from "../images/op3.jpg"
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

function Papers() {
  return (
    <div className="Page">

      <div className="Text-and-image Reverse-column-mobile BackgroundImage">
        <div style={{marginLeft: '10%'}}>
          <div className="Primary-text">
          מאמרים
          </div>
        </div>
        <img className="Image" src={Efrat} alt={"Efrat"} />
      </div>

      <div className="Text-and-image Reverse-column-mobile">
        <iframe width="100%" height="1200" src="https://www.themarker.com/labels/optics/2021-03-07/ty-article-labels/0000017f-f88f-d887-a7ff-f8efbe530000" title="Page"></iframe>

      </div>

  </div>
  );
}

export default Papers;