import React from "react";
import Efrat from "../images/Efrat.jpg"
import logo_no_text from "../images/logo_no_text.jpg"

function Clinic() {
  return (
    <div className="Page">
    <div className="Text-and-image Reverse-column-mobile BackgroundImage">
        <div style={{marginLeft: '10%'}}>
          <div className="Primary-text">
          מרפאות
          </div>
        </div>
        <img className="Image" src={Efrat} alt={"Efrat"} />
    </div>

      <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column'}}>
        <p className="Secondary-text" style={{color: 'black', textAlign: 'right', fontSize: '2em', lineHeight: 2, margin: '5%'}}>
            מרפאת רחובות - דרך ירושלים 15
          <br />
          מרפאת חולון - רבינוביץ׳ 11, מרכז סאדאב
          <br />
          מרפאת אשדוד - כנרת 7, בית הרופאים
        </p>
        <img className="Image" src={logo_no_text} alt={"Logo no text"} style={{width: 320, height: 320, marginBottom: '2%'}} />
      </div>

  </div>
  );
}

export default Clinic;